<template>
  <div class="col-12 col-lg-10 c g">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        عدد الحجوزات
                    </th>
                    <th>
                        الاجمالي
                    </th>
                    <th>
                        مستحق الشركات
                    </th>
                    <th>
                        عمولتك
                    </th>
                    <th>
                        الباقي
                    </th>
                </thead>
                <tbody>
                    <tr v-for="order in arr" :key="order._id">
                        <td>
                            {{ order.date }}
                        </td>
                        <td>
                            {{ order.orders.length }}
                        </td>
                        <td>
                            {{ order.reseller_total + order.extra }} ريال
                        </td>
                        <td>
                            {{ order.total }} ريال
                        </td>
                        <td>
                            {{ order.tips }} ريال
                        </td>
                        <td>
                            {{ (order.reseller_total + order.extra ) - (order.total + order.tips) }} ريال
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            arr: []
        }
    },
    created(){
        var g = this;
        g.loading = true;
        $.post(api + '/cash/orders/archive', {
            jwt: this.jwt,
        }).then(function(r){
            g.loading = false;
            if(r.status != 100){
                alert(r.response)
            }else{
                g.arr = r.response
            }
        }).fail(function(){
            g.loading = false;
            alert("حدث مشكلة في الاتصال")
        })
    }
}
</script>

<style>

</style>